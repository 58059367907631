import React, { useCallback, useMemo, useState } from "react"

import Game from '../components/Game'
import { gameConfigs } from '../ParksGame/data/data'
import Difficulty from "../components/Difficulty"

const ParksPage = () => {
  const [isSelected, setIsSelected] = useState(false)
  const [gameConfig, setGameConfig] = useState(gameConfigs[0])

  const changeDifficulty = useCallback((index) => {
    setGameConfig(gameConfigs[index])
    setIsSelected(true)
  }, [setGameConfig, setIsSelected])

  const buttons = useMemo(() => {
    return [
      {
        id: 0,
        text: 'Легкий',
        onClick: () => changeDifficulty(0),
      },
      {
        id: 1,
        text: 'Средний',
        onClick: () => changeDifficulty(1),
      },
      {
        id: 2,
        text: 'Сложный',
        onClick: () => changeDifficulty(2),
      },
    ]
  }, [changeDifficulty])

  return (
    <div className='main-container'>
        {
          isSelected ? 

            <Game gameConfig={gameConfig} />

          :

            <Difficulty buttons={buttons} />
        }
    </div>
  )
}

export default React.memo(ParksPage)
