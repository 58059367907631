import React from 'react'

const ParkCard = ({ id, name, currentParkId, open }) => {
  return (
    <article className="park-card">
      <button className={`${id === currentParkId ? '_problem' : ''} park-card__button`} onClick={() => open(id)}>{name}</button>
    </article>
  )
}

export default ParkCard