import React from 'react'

const Score = ({ score }) => {
  return (
    <div className="score">
        <input className={`${score < 30 ? 'minScoreRange' : score > 30 && score <= 50 ? 'underCenterScoreRange' : score > 50 && score < 75 ? 'aboveCenterScoreRange' : 'maxScoreRange'}`} type='range' value={score} />
    </div>
  )
}

export default Score