import React from 'react'

import { useParkGame } from '../ParksGame/hooks/useParkGame'
import Content from '../ParksGame/components/Content'
import MyPopup from './UI/MyPopup'

import ParkPopup from '../ParksGame/components/ParkPopup'

const Game = ({ gameConfig }) => {
    const { parksList, problems, time } = gameConfig

    const { isStarted, start, parks, currentParkId, isOpen, open, openPark, score, success, failed, minutes, seconds, tick } = useParkGame(parksList, problems, time)

    return (
        <>
            <Content isStarted={isStarted} start={start} parks={parks} currentParkId={currentParkId} open={open} score={score} />

            <MyPopup isOpen={isOpen} children={<ParkPopup isStarted={isStarted} openPark={openPark} success={success} failed={failed} minutes={minutes} seconds={seconds} tick={tick} />} />
        </>
    )
}

export default Game