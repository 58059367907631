import { useState } from "react"

import { getRandomNumber } from "../helpers/parkGameHelpers"

export const useParkGame = (parksList, problems, time) => {
    const [isStarted, setIsStarted] = useState(false)
    const [isEnded, setIsEnded] = useState(false)
    const [score, setScore] = useState(50)

    const [parks, setParks] = useState(parksList)
    const [currentParkId, setCurrentParkId] = useState(null)

    const [isOpen, setIsOpen] = useState(false)
    const [openPark, setOpenPark] = useState(null)

    const [currentProblemId, setCurrentProblemId] = useState(0)

    const [[minutes, seconds], setTimer] = useState([time.minutes, time.seconds])

    const nextProblem = () => {
        setCurrentProblemId(currentProblemId => currentProblemId + 1)
    }

    const next = () => {
        const array = [...parks]

        if (currentParkId !== null) {
            const oldFindPark = array.find(park => park.id === currentParkId)
            const oldIndex = array.indexOf(oldFindPark)
            array[oldIndex].problem = ''
        }

        const randomParkId = getRandomNumber(parks)
        const problem = problems[currentProblemId].text

        const newFindPark = array.find(park => park.id === randomParkId)
        const newIndex = array.indexOf(newFindPark)
        newFindPark.problem = problem
        array[newIndex] = newFindPark

        const sorted = array.sort((firstPark, secondPark) => secondPark.problem.length - firstPark.problem.length)

        setParks(sorted)
        setCurrentParkId(randomParkId)
        nextProblem()
    }

    const start = () => {
        setIsStarted(true)
        next()
    }

    const end = () => {
        const array = [...parks]
        array[currentParkId].problem = ''

        setIsOpen(false)
        setIsEnded(true)
        setParks(array)
        setCurrentParkId(null)
    }

    const success = () => {
        if (currentProblemId !== problems.length) {
            setIsOpen(false)
            next()
        } else {
            end()
        }

        setScore(score => score + 7)
        setTimer([1000, 0])
    }

    const failed = () => {
        if (currentProblemId !== problems.length) {
            setIsOpen(false)
            next()
        } else {
            end()
        }

        setScore(score => score - 3)
        setTimer([1000, 0])
    }

    const tick = () => {
        if (minutes === 1000) {
            setTimer([minutes, seconds])
        } else if (minutes === 0 && seconds === 0) {
            failed()
        } else if (seconds === 0) {
            setTimer([minutes - 1, 59])
        } else {
            setTimer([minutes, seconds - 1])
        }
    }

    const open = (parkId) => {
        setTimer([time.minutes, time.seconds])

        if (currentParkId === parkId) {
            const findPark = parks.find(park => park.id === parkId)

            setOpenPark(findPark)
            setIsOpen(true)
        }
    }

    return {
        isStarted,
        start,
        parks,
        currentParkId,
        isOpen,
        open,
        openPark,
        score,
        success,
        failed,
        minutes,
        seconds,
        tick,
    }
}