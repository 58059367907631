export const easyParksList = [
    {
        id: 0,
        name: 'Горкинско-Ометьевский Лес',
        information: 'Это самый крупный Казанский парк, основанный 25 декабря 2016 года. Он состоит из нескольких лесных внутригородских массивов, а из деревьев чаще всего встречаются дуб, клен. липа. Парк предназначен для досуга и семейного отдыха. Общая площадь территории немного превышает 66 га. Находится участок в Советском районе Казани.',
        problem: '',
    },
    {
        id: 1,
        name: 'Парк Горького',
        information: 'Парк с многолетней историей был открыт 24 мая 1939 года, площадь которого примерно 15 га. Местные когда-то называли лес «Русской Швейцарией». Сейчас в парке можно увидеть множество достопримечательностей, таких как фонтаны, скульптуры, аллеи, цветники и другие объекты ландшафтного дизайна. Кроме того, на территории парка расположены различные спортивные и детские площадки, кафе и рестораны.',
        problem: '',
    },
    {
        id: 2,
        name: 'Черное озеро',
        information: 'Парк с таким необычным название имеет несколько версий своего происхождения: по одной из версий, название озеро получило как раз из-за своей сильной загрязненности, по другой здесь когда-то плавали очень редкие птицы – черные лебеди, в честь которых и стали так именовать водоём. Парк площадью 4,9 га открылся 18 сентября 2016 года.',
        problem: '',
    },
    {
        id: 3,
        name: 'Парк имени Урицкого',
        information: 'Один из крупнейших парков города Казани, расположенный на берегу реки Волга. Он был основан в 1932 году и назван в честь революционера Михаила Урицкого. Красивый, уютный, небольшой городской парк для отдыха взрослых и детей, занятий физкультурой и спортивных мероприятий.',
        problem: '',
    },
    {
        id: 4,
        name: 'Парк Крылья Советов',
        information: 'Зона отдыха появилась ещё в далёком 1939 году. Почтенный возраст делает сквер одним из старейших в городе. К советскому наследию относились немногочисленные аттракционы, демонтированные в 2008–2013 годах. Визитная карточка сквера — главная входная группа, оформленная в виде массивных колонн со скульптурами рабочих.',
        problem: '',
    },
    {
        id: 5,
        name: 'Бульвар «Белые Цветы»',
        information: 'Бульвар «Белые цветы» — общественное пространство в Казани вдоль улицы Абсалямова, разработанное вместе с жителями района в 2018 году. В рамках проекта был сформирован ландшафт, завезена плодородная земля и уже высажено более 1000 крупномерных деревьев и многолетних кустарников.',
        problem: '',
    },
    {
        id: 6,
        name: 'ПИК / Нокса Парк',
        information: 'Уютный проект в Казани с зелёным двором-парком и собственной инфраструктурой: школой и детским садом. Квартал находится в Советском районе. На территории предусмотрены детские игровые и спортивные площадки, зоны для отдыха взрослого населения, озеленение, наземные многоуровневые паркинги.',
        problem: '',
    },
    {
        id: 7,
        name: 'Лес на Дубравной',
        information: 'Это часть Столбищенского участкового лесничества, которое находится в федеральной собственности. Здесь растут 150-летние дубы, которые и дали название улице, водятся зайцы и лисы, а также растут цветы, занесенные в Красную Книгу.',
        problem: '',
    },
    {
        id: 8,
        name: 'Детский Парк Калейдоскоп',
        information: 'Детский парк «Калейдоскоп» открылся после реставрации 30 августа 2017. Реконструированный парк рассматривается как удачный пример создания детского паркового пространства, соответствующего современным требованиям урбанистики.',
        problem: '',
    },
]

export const easyProblems = [
    {
        id: 0,
        text: 'Детские качели скрипят и находятся в аварийном состоянии',
    },
    {
        id: 1,
        text: 'На территории парка отсутствуют будки с водой и едой для перекуса',
    },
    {
        id: 2,
        text: 'Отсутствует ограждение для выгула собак / большие собаки без намордника (отсутствие предупредительных знаков/правил при входе в парк)',
    },
    {
        id: 3,
        text: 'Шум и неприятные запахи от близлежащих предприятий или автомобильных дорог',
    },
    {
        id: 4,
        text: 'Неработающий фонтан/отсутствие правил безопасности',
    },
    {
        id: 5,
        text: 'Сломанные скамейки',
    },
    {
        id: 6,
        text: 'Отсутствие защиты от солнца, что может привести к солнечному удару',
    },
]

export const mediumProblems = [
    {
        id: 0,
        text: 'Некоторые виды птиц,оказавшиеся в бедственном положении в холодное время года',
    },
    {
        id: 1,
        text: 'Разрушение экологии различными производственными отходами.Загрязнение окружающей среды',
    },
    {
        id: 2,
        text: 'Необходимость обновления зон отдыха с детьми',
    },
    {
        id: 3,
        text: 'Велосипедисты ездят по одной дорожке где и люди',
    },
    {
        id: 4,
        text: 'Человеку стало плохо,ближайшая больница находится далеко',
    },
    {
        id: 5,
        text: 'Отсутствие пандусов и наличие старых ступенек,создающих трудности в передвижении инвалидов и стариков',
    },
    {
        id: 6,
        text: 'Отсутствия контроля за недобросовестными гражданами,которые мусорят или хулиганят',
    }
]

export const hardProblems = [
    {
        id: 0,
        text: 'Вандализм на малых архитектурных формах и/или в парке',
    },
    {
        id: 1,
        text: 'Выгул собак на территории парка, хозяин не убирает за собакой продукты жизнедеятельности',
    },
    {
        id: 2,
        text: 'Кормление голубей, которые могут быть разносчиками заразы',
    },
    {
        id: 3,
        text: 'Кормление уток хлебом, что приводит к смерти животных',
    },
    {
        id: 4,
        text: 'Отсутствие исторической справки про парк',
    },
    {
        id: 5,
        text: 'Несвоевременный вывоз мусора из парков',
    },
    {
        id: 6,
        text: 'не хватает общественных туалетов в парках, детских комнат, где можно переодеть ребёнка',
    }
]

export const marksClasses = [
    '_first-mark',
    '_second-mark',
    '_third-mark',
    '_fourth-mark',
    '_fifth-mark',
    '_sixth-mark',
    '_seventh-mark',
    '_eighth-mark',
    '_ninth-mark',
]

export const gameConfigs = [
    {
        parksList: easyParksList,
        problems: easyProblems,
        time: {
            minutes: 2,
            seconds: 0,
        }
    },
    {
        parksList: easyParksList,
        problems: mediumProblems,
        time: {
            minutes: 1,
            seconds: 30,
        }
    },
    {
        parksList: easyParksList,
        problems: hardProblems,
        time: {
            minutes: 1,
            seconds: 0,
        }
    },
]