import React from "react"
import ParksSchemeList from "./ParksSchemeList"

import parkMarkImage from "../../images/icon.svg"

const ParksScheme = ({ parks, currentParkId, open }) => {
  return (
    <ParksSchemeList parks={parks} currentParkId={currentParkId} open={open} />
  )
}

export default ParksScheme