import React from 'react'
import Timer from '../../components/UI/Timer'

const ParkPopup = ({ isStarted, openPark, success, failed, minutes, seconds, tick }) => {
  return (
    <article className="park-popup">
        {
            openPark !== null ?
                <div className="open-park">
                    <div className="open-park__header">
                        <h3 className="open-park__title">{openPark.name}</h3>

                        <Timer minutes={minutes} seconds={seconds} tick={tick} />
                    </div>

                    <div className="open-park__text">
                        <h4 className="open-park__text-heading">Информация</h4>
                        <p>{openPark.information}</p>
                    </div>

                    <div className={`${openPark.problem !== '' ? '' : '_hidden'} open-park__text`}>
                        <h4 className="open-park__text-heading">Проблема</h4>
                        <p>{openPark.problem}</p>
                    </div>

                    <div className={`${openPark.problem !== '' ? '_grid' : '_hidden'} open-park__buttons`}>
                        <button className="my-button" onClick={success}>Выполнено</button>
                        <button className="my-button _red-button" onClick={failed}>Не выполнено</button>
                    </div>
                </div>
            :

                null
        }
    </article>
  )
}

export default React.memo(ParkPopup)