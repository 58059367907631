import React from "react"
import Header from "./Header"
import ParksScheme from "./ParksScheme"
import ParksList from "./ParksList"
import Score from "./Score"

const Content = ({ isStarted, start, parks, currentParkId, open, score }) => {
  return (
    <div className="content">
      <Header isStarted={isStarted} start={start} />

      <main className="main">
        <section className="scheme">
          <div></div>
          <ParksScheme parks={parks} currentParkId={currentParkId} open={open} />
          <ParksList parks={parks} currentParkId={currentParkId} open={open} />
        </section>

        <section className="score">
          <Score score={score} />
        </section>
      </main>
    </div>
  )
}

export default React.memo(Content)
