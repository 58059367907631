import React from 'react'

import MyButton from './MyButton'

const ButtonSet = ({ buttons }) => {

    return (
        <div className='button-set'>
            {
                buttons.map(button => (
                    <MyButton key={button.id} text={button.text} onClick={button.onClick} />
                ))
            }
        </div>
    )
}

export default ButtonSet