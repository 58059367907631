import React from "react"
import ParkMark from "./ParkMark"

import { marksClasses } from "../data/data"

const ParksSchemeList = ({ parks, currentParkId, open }) => {
  return (
    <ul className="parks-scheme__list">
        {
            parks.map(park => (
                <li key={park.id} className={`parks-scheme__list-item ${marksClasses[park.id]}`}>
                    <ParkMark id={park.id} name={park.name} currentParkId={currentParkId} open={open} />
                </li>
            ))
        }
    </ul>
  )
}

export default ParksSchemeList