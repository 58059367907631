import React from 'react'

const MyPopup = ({ isOpen, children }) => {
  return (
    <div className={`my-popup ${isOpen ? '_flex' : '_hidden'}`}>
      {children}
    </div>
  )
}

export default React.memo(MyPopup)