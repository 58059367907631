import React from "react"

import logo from "../../images/logo.svg"

const Header = ({ isStarted, start }) => {
  return (
    <header className="header">
      <img className="logo" src={logo} alt="Логотип Команды Казани" />

      <h1 className="title">Парковые герои</h1>

      <button className={`my-button ${isStarted ? '_visually-hidden' : ''}`} onClick={start}>Начать</button>
    </header>
  )
}

export default Header
