import React from 'react'

import ButtonSet from './UI/ButtonSet'

const Difficulty = ({ buttons }) => {

    return (
        <div className='difficulty'>
            <p className='difficulty__title'>Выберите режим игры:</p>

            <ButtonSet buttons={buttons} />
        </div>
    )
}

export default Difficulty