import React from "react"
import ParkCard from "./ParkCard"

const ParksList = ({ parks, currentParkId, open }) => {
  return (
    <ul className="park-list">
        {
            parks.map(park => (
                <li key={park.id} className="park-list__item">
                    <ParkCard id={park.id} name={park.name} currentParkId={currentParkId} open={open} />
                </li>
            ))
        }
    </ul>
  )
}

export default ParksList