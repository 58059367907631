import React, { useEffect } from "react"

const Timer = ({ minutes, seconds, tick }) => {
  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(timerId)
  })

  return (
    <p className="timer">{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</p>
  )
}

export default React.memo(Timer)